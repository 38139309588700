import { filledModules, printableDefault } from '@/utils/documentHelper'

const printableDocsMixin = () => ({
  data: () => ({
    defaultReviews: [
      {
        label: 'Solicitação de exames',
        identifier: 2,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Solicitação de cirurgia',
        identifier: 10,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Solicitação de APA (Avaliação Pré Anestésica)',
        identifier: 3,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Solicitação de tratamento',
        identifier: 23,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Encaminhamento',
        identifier: 4,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Prescrição Medicamentosa',
        identifier: 5,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Prescrição do óculos',
        identifier: 6,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM', 'ESSILOR_ATTENDANCE'],
      },
      {
        label: 'Prescrição de lentes de contato',
        identifier: 13,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM', 'ESSILOR_ATTENDANCE'],
      },
      {
        label: 'Orientação ao paciente',
        identifier: 14,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Declaração de comparecimento',
        identifier: 7,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Declaração de comparecimento (acompanhante)',
        identifier: 8,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Atestado',
        identifier: 1,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Laudo médico',
        identifier: 11,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Mapeamento de retina/fundoscopia',
        identifier: 12,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Pressão intraocular',
        identifier: 15,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM', 'ESSILOR_ATTENDANCE'],
      },
      {
        label: 'Gonioscopia',
        identifier: 16,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Biomicroscopia',
        identifier: 17,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Motilidade ocular extrínseca',
        identifier: 18,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Curva tensional',
        identifier: 19,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'BV_PRESCRIPTION', 'BV_EXAM', 'EXAM'],
      },
      {
        label: 'Biometria',
        identifier: 26,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Retinografia',
        identifier: 27,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Paquimetria',
        identifier: 28,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Campo visual',
        identifier: 20,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'OCT (Tomografia de Coerência Óptica) de Mácula',
        identifier: 25,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'OCT (Tomografia de Coerência Óptica) de Nervo Óptico',
        identifier: 29,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Tomografia de Córnea (Galilei)',
        identifier: 21,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Microscopia Especular de Córnea',
        identifier: 30,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Potencial de Acuidade Macular (PAM)',
        identifier: 31,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Ceratometria',
        identifier: 32,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Topografia',
        identifier: 24,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM', 'ESSILOR_ATTENDANCE'],
      },
      {
        label: 'Triagem',
        identifier: 33,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Teste Acuidade Visual',
        identifier: 34,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Teste de Ishihara',
        identifier: 35,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'BV_PRESCRIPTION', 'BV_EXAM'],
      },
      {
        label: 'Prescrição do óculos extra',
        identifier: 36,
        toPrint: false,
        toSign: false,
        scopes: ['ESSILOR_ATTENDANCE'],
      },
      {
        label: 'Pressão intraocular corrigida',
        identifier: 37,
        toPrint: false,
        toSign: false,
        scopes: ['ESSILOR_ATTENDANCE'],
      },
      {
        label: 'Aberrometria',
        identifier: 38,
        toPrint: false,
        toSign: false,
        scopes: ['ESSILOR_ATTENDANCE'],
      },
      {
        label: 'Complemento Glaucoma',
        identifier: 39,
        toPrint: false,
        toSign: false,
        scopes: ['ATTENDANCE', 'SURGERY', 'EXAM'],
      }
    ],
    filled: [],
  }),
  computed: {
    checkAttendanceReviewPrint() {
      return this.reviews.some(el => el.toPrint)
    },
    reviews() {
      return this.customReviews || this.defaultReviews
    }
  },
  methods: {
    onShow() {
      this.filled = filledModules(this.form)
      this.reviews.map(review => {
        if (printableDefault(review.identifier) && ![5, 9, 13, 22].includes(review.identifier)) {   
          review.toPrint = this.filled.includes(review.identifier)
        }
        else if ([5, 9].includes(review.identifier)) {
          review.toPrint = (this.filled.includes(5) || this.filled.includes(9))
        }
        else if ([13, 22].includes(review.identifier)) {
          review.toPrint = (this.filled.includes(13) || this.filled.includes(22))
        }
        if([2 , 23 , 10 , 3 , 4 , 5 , 6 , 13 , 14 , 1 , 11, 39].includes(review.identifier)){
          review.active = review.toPrint
        }else{
          review.active = review.toPrint
          review.toPrint = false
        }
      })
    },
    filteredReviews(type) {
      return this.reviews.filter(el => el?.scopes?.includes(type))
    },
  }
});

export default printableDocsMixin
