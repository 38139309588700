<template>
  <b-modal
    id="print-medical-records-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @show="onShow"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Impressão de documentos</p>
      <Close
        class="close"
        @click="$bvModal.hide('print-medical-records-modal')"
      />
    </div>
    <div class="body">
      <div>
        <p class="text mt-3 mb-3" id="title">
          Selecione na lista abaixo quais documentos deseja
          <strong>imprimir</strong>.
        </p>
        <div class="alert">
          No modo <strong>impressão rápida</strong> os documentos impressos <strong>não são assinados</strong> mesmo com o certificado ativo
        </div>
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <div class="div-table">
            <table id="table-print" aria-describedby="title">
              <tr>
                <th class="table-header">Documentos</th>
                <th class="table-header text-center">
                  <strong>Imprimir</strong>
                </th>
              </tr>

              <tr class="tr" v-for="(review, index) in filteredReviews(attendance?.type)" :key="index">
                <td class="tdLabel" v-if="review?.active">{{ review.label }}</td>
                <td class="text-center" v-if="review?.active">
                  <label class="checkbox" align="center">
                    <input
                      type="checkbox"
                      v-model="review.toPrint"
                      :disabled="![5,13].includes(review.identifier) ? !filled.includes(review.identifier) : (review.identifier == 5 && !filled.includes(5) && !filled.includes(9)) || (review.identifier == 13 && !filled.includes(13) && !filled.includes(22))"
                    />
                    <span class="checkmark" :id="`print_${review.label}`">
                      <Check />
                    </span>
                    <b-tooltip
                      v-if="![5,13].includes(review.identifier) ? !filled.includes(review.identifier) : (review.identifier == 5 && !filled.includes(5) && !filled.includes(9)) || (review.identifier == 13 && !filled.includes(13) && !filled.includes(22))"
                      :target="`print_${review.label}`"
                      placement="left"
                    >
                      Para imprimir este documento, <br />
                      preencha o campo dentro da consulta
                    </b-tooltip>
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </perfect-scrollbar>
      </div>

      <b-row class="mt-4">
        <b-col class="text-right">
          <b-button
            variant="primary"
            :disabled="!checkAttendanceReviewPrint"
            @click="createDocumentAndPrint"
          >
            Imprimir
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import printableDocsMixin from '@/mixins/Attendance/printableDocsMixin'

export default {
  name: 'PrintMedicalRecordsModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Check: () => import('@/assets/icons/check.svg')
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      attendance: state => state.attendance.attendance,
      form: state => state.attendance.form,
    })
  },
  mixins: [printableDocsMixin('form')],
  methods: {
    ...mapActions('attendance', ['finishAttendance']),
    async createDocumentAndPrint() {
      const payload = this.reviews.reduce((acc, review) => {
        if (!review.active) {
          return acc
        }

        if (review.toPrint) {
          acc.toPrint.push(review.identifier)
        }
        if (review.toSign) {
          acc.toSign.push(review.identifier)
        }
        return acc
      }, { toPrint: [], toSign: [], quickPrint: true });

      this.filled.includes(9) &&
        payload.toPrint.includes(5) &&
          payload.toPrint.push(9)
          
      this.filled.includes(22) &&
        payload.toPrint.includes(13) &&
          payload.toPrint.push(22)

      !this.filled.includes(13) &&
        payload.toPrint.includes(13) &&
          payload.toPrint.splice(payload.toPrint.indexOf(13), 1)

      this.filled.includes(39)
      && payload.toPrint.includes(39)
      && payload.toPrint.push(40)
      && payload.toPrint.push(41)
      && payload.toPrint.push(42)
      && payload.toPrint.push(43)

      this.finishAttendance(payload)
      this.$bvModal.hide('print-medical-records-modal')
    }
  },
}
</script>

<style lang="scss">
#print-medical-records-modal {
  font-family: 'Nunito Sans';
  .modal-body {
    padding: 0 !important;

    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-weight: 600;
        font-size: 18px;
        color: var(--type-active);
        margin: 0;
      }

      .close {
        width: 24px;
        height: 24px;
        fill: black;
        cursor: pointer;
      }
    }

    .body {
      table {
        width: -webkit-fill-available;
      }

      .table-header {
        font-family: Nunito Sans;
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
        color: var(--type-active);
      }

      .hr-table {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      padding: 24px;

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 8px;
      }

      .alert {
        padding: 16px;
        border: 1px solid var(--light-orange-500);
        border-radius: 8px;
        margin: 10px 0;
        color: var(--light-orange-500);
        text-align: center;
        background: #fff0db;
      }

      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 32px;
      }

      .checkbox {
        margin-top: -12px;
        display: initial !important;
      }
      .checkbox.bold {
        font-weight: 700;
        margin-bottom: 16px;
      }

      input[type='checkbox']:disabled ~ .checkmark {
        background-color: var(--neutral-200);
      }

      .tr {
        border-bottom: 1px solid var(--neutral-300);
      }

      .tdLabel {
        font-size: 16px;
        color: var(--type-active);
        font-weight: 400;
        padding: 10px 0px;
      }
    }

    .div-table {
      max-height: 60vh;
    }
  }
}
</style>
